import { Grid } from "@mui/material";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { getSupplyValueInUnit } from "@sellernote/_shared/src/utils/forwarding/bid";

import { sumWeightToKg } from "../../utils";

import SADSTable, { TableBodyRow, TableHeadCell } from "../../../SADSTable";
import TotalTable, { TableBodyTotalRow } from "../../TotalTable";

type CellKey =
  | "NO"
  | "품명"
  | "포장유형"
  | "수량"
  | "물동량"
  | "부피"
  | "중량"
  | "위험물"
  | "냉동/냉장여부"
  | "2단적재";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "NO", label: "NO", width: 100 },
  { cellKey: "품명", label: "품명", width: 180 },
  { cellKey: "포장유형", label: "포장유형", width: 120 },
  { cellKey: "수량", label: "수량", align: "right", width: 120 },
  { cellKey: "물동량", label: "물동량", align: "right", width: 120 },
  { cellKey: "부피", label: "부피", align: "right", width: 120 },
  { cellKey: "중량", label: "중량", align: "right", width: 120 },
  { cellKey: "위험물", label: "위험물", align: "right", width: 120 },
  {
    cellKey: "냉동/냉장여부",
    label: "냉동/냉장여부",
    align: "right",
    width: 120,
  },
  { cellKey: "2단적재", label: "2단적재", align: "right", width: 120 },
];

type TotalKey = "총계" | "수량" | "물동량" | "부피" | "중량" | "empty";

function LCLAndAIRItemList({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const bodyRowList: TableBodyRow<CellKey>[] = (
    bidDetail.itemGroupList || []
  ).map(({ item }) => {
    return {
      rowKey: item.id,
      NO: { value: item.id },
      품명: { value: item.name },
      포장유형: { value: item.packingType },
      수량: { value: toThousandUnitFormat(item.quantity) },
      물동량: { value: "-" }, // 총 물동량만 표기
      부피: {
        value: item.horizontal
          ? `${item.cbm}CBM (${item.horizontal}x${item.vertical}x${item.height})${item.volumeUnit}`
          : `${item.cbm}CBM`,
      },
      중량: {
        value: (
          <>
            {item.weight}
            {item.weightUnit}
          </>
        ),
      },

      위험물: {
        value: item.isDangerous ? "포함" : "미포함",
      },
      "냉동/냉장여부": {
        value: changeBooleanValueToKr(item.needRefrigeration),
      },
      "2단적재": {
        value: item.canStack ? "가능" : "불가",
      },
    };
  });

  const totalWeight = sumWeightToKg(
    bidDetail.itemGroupList.map((v) => v.item) || []
  );

  const totalRow: TableBodyTotalRow<TotalKey> = {
    총계: { value: "총계", isLabel: true, colSpan: 3, align: "center" },
    수량: {
      value: `${toThousandUnitFormat(
        bidDetail.itemGroupList?.reduce(
          (acc, cur) => acc + (cur.item.quantity || 0),
          0
        )
      )}`,
      align: "right",
    },
    물동량: {
      value: `${getSupplyValueInUnit(bidDetail.freightType, bidDetail.supply)}`,
      align: "right",
    },
    부피: {
      value: `${bidDetail.itemGroupList?.reduce(
        (acc, cur) => acc + (cur.item.cbm || 0),
        0
      )}CBM`,
      align: "right",
    },
    중량: {
      value: `${totalWeight}KG`,
      align: "right",
    },
    empty: { value: "", colSpan: 3 },
  };

  const visibleTotalTable = bodyRowList.length > 1;

  return (
    <Grid direction="column" spacing={2}>
      <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />

      {visibleTotalTable && (
        <TotalTable headCellList={headCellList} totalRow={totalRow} />
      )}
    </Grid>
  );
}

export default LCLAndAIRItemList;
