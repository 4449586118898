import { Grid } from "@mui/material";

import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import { toThousandUnitFormat } from "@sellernote/_shared/src/utils/common/number";
import { getSupplyValueInUnit } from "@sellernote/_shared/src/utils/forwarding/bid";

import SADSTable, { TableBodyRow, TableHeadCell } from "../../../SADSTable";

type CellKey =
  | "NO"
  | "품명"
  | "포장유형"
  | "수량"
  | "물동량"
  | "CBM"
  | "중량"
  | "위험물"
  | "냉동/냉장여부"
  | "2단적재";

const headCellList: TableHeadCell<CellKey>[] = [
  { cellKey: "NO", label: "NO", width: 100 },
  { cellKey: "품명", label: "품명" },
  { cellKey: "포장유형", label: "포장유형" },
  { cellKey: "수량", label: "수량", align: "right" },
  { cellKey: "물동량", label: "물동량", align: "right" },
  { cellKey: "CBM", label: "CBM", align: "right" },
  { cellKey: "중량", label: "중량", align: "right" },
  { cellKey: "위험물", label: "위험물" },
  { cellKey: "냉동/냉장여부", label: "냉동/냉장여부" },
  { cellKey: "2단적재", label: "2단적재" },
];

function LCLAndAIRItemList({ bidDetail }: { bidDetail: AdminBidDetail }) {
  const bodyRowList: TableBodyRow<CellKey>[] = (
    bidDetail.itemGroupList || []
  ).map(({ item }) => {
    return {
      rowKey: item.id,
      NO: { value: item.id },
      품명: { value: item.name },
      포장유형: { value: item.packingType },
      수량: { value: toThousandUnitFormat(item.quantity) },
      물동량: {
        value: getSupplyValueInUnit(bidDetail.freightType, bidDetail.supply),
      },
      CBM: {
        value: `${item.cbm}CBM`,
      },
      중량: {
        value: (
          <>
            {item.weight}
            {item.weightUnit}
          </>
        ),
      },
      위험물: {
        value: item.isDangerous ? "포함" : "미포함",
      },
      "냉동/냉장여부": {
        value: changeBooleanValueToKr(item.needRefrigeration),
      },
      "2단적재": {
        value: item.canStack ? "가능" : "불가",
      },
    };
  });

  return (
    <Grid direction="column" spacing={2}>
      <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
    </Grid>
  );
}

export default LCLAndAIRItemList;
