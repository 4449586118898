import { toKg } from "@sellernote/_shared/src/utils/common/number";

const sumWeightToKg = (
  itemList: {
    weight: number;
    weightUnit: "TON" | "KG" | "ton" | "kg" | "NONE";
  }[]
) => {
  return itemList.reduce((acc, cur) => {
    if (cur.weightUnit === "NONE") {
      return acc;
    }

    return acc + toKg(cur.weight, cur.weightUnit);
  }, 0);
};

export { sumWeightToKg };
